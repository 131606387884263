import { Accordion } from 'react-bootstrap';
import faqs from '../data/FAQ.json';

function FAQ() {

    return (
        <Accordion flush>
            {
                faqs.map(({ question, answer }, index) => (
                    <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>{question}</Accordion.Header>
                        <Accordion.Body>{answer}</Accordion.Body>
                    </Accordion.Item>
                ))
            }
        </Accordion>
    );
}

export default FAQ;