import Section from '../components/section';
import { Col, Image, Row } from 'react-bootstrap';
import feaureList from '../data/FeatureList.json';
import cameraImage from '../images/camera.jpg';

function FeatureSection() {

    return (
        <Section id='features' title='Features' hideTitle={true}>
            <Row>
                <Col sm={12} md={6}>
                    {
                        feaureList.map(({ name, description }, index) => (
                            <div className='p-4 feature-block' key={index}>
                                <div className='display-6'>{name}</div>
                                <div>{description}</div>
                                <hr />
                            </div>
                        ))
                    }
                </Col>
                <Col className='d-flex justify-content-center align-items-center'>
                    <Image className='mx-5' src={cameraImage} style={{ maxWidth: '70%' }} />
                </Col>
            </Row>
        </Section>
    );
}

export default FeatureSection;