import Section from "../components/section";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Image, Row } from "react-bootstrap";
import { faApple, faGooglePlay, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logoLight from '../images/LineKamLogoLight.svg'

function FooterSection() {

    var currentYear = new Date().getFullYear();

    return (
        <Section id="footer" hideTitle={true} className="bg-dark">
            <Row>
                <Col>
                    <Image className="logo" src={logoLight} />
                    <div className="pt-3">
                        <a href="https://www.linkedin.com/company/linekam" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} size="xl" className="px-2" />
                        </a>
                        <a href="mailto:info@linekam.com" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faEnvelope} size="xl" className="px-2" />
                        </a>
                        <a href="https://www.apple.com/app-store/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faApple} size="xl" className="px-2" />
                        </a>
                        <a href="https://play.google.com/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faGooglePlay} size="xl" className="px-2" />
                        </a>
                    </div>
                </Col>
                <Col className="text-end text-light">
                    <p>
                        's-Hertogenbosch <br />
                        The Netherlands
                    </p>
                </Col>
            </Row>
            <div className="text-center">
                <p className="fs-6 text-dark-emphasis">Linekam {currentYear} ©</p>
            </div>
        </Section>
    );
}

export default FooterSection;