import { useState } from 'react';
import { Alert, Button, FloatingLabel, Form } from 'react-bootstrap';

function ContactForm() {

    const interestOptions = ["Market Testing", "Buying", "Reselling"];

    // State
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [interest, setInterest] = useState(interestOptions[0]);
    const [comment, setComment] = useState("");
    const [buttonText, setButtonText] = useState("Submit");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [alertVariant, setAlertVariant] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [alertHidden, setAlertHidden] = useState(true);

    function onSubmit(event) {
        event.preventDefault();

        setButtonDisabled(true);
        setButtonText("Sending request...");

        fetch("https://prod-14.westeurope.logic.azure.com/workflows/6024deed25514c32a48c199ceab189f2/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=l9z4_mL65KIkqdOpXckHoijcHmoXYsInOUMeb1xDODg", {
            method: "POST",
            body: JSON.stringify({
                "name": name,
                "email": email,
                "interest": interest,
                "comment": comment
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                setButtonText("Submit")
                setButtonDisabled(false);

                if (response.status === 200) {
                    showAlert("success", "Your request has been sent. We will be in contact soon.");
                    clearForm();
                }
                else {
                    showAlert("danger", "Your request could not be sent.");
                }

                console.log(response)
            });
    }

    function showAlert(variant, message) {
        setAlertVariant(variant);
        setAlertMessage(message);
        setAlertHidden(false);

        setTimeout(() => setAlertHidden(true), 5000);
    }

    function clearForm() {
        setName("");
        setEmail("");
        setComment("");
    }

    return (
        <Form onSubmit={onSubmit}>
            <Alert variant={alertVariant} hidden={alertHidden}>{alertMessage}</Alert>
            <FloatingLabel controlId='floatingName' label='Name'>
                <Form.Control
                    className='mb-3'
                    type='text'
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    required />
            </FloatingLabel>

            <FloatingLabel controlId='floatingEmailAddress' label='Email address'>
                <Form.Control
                    className='mb-3'
                    type='email'
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required />
            </FloatingLabel>

            <FloatingLabel controlId='floatingInterest' label='I am interested...'>
                <Form.Select className='mb-3' name='interest' value={interest} onChange={(event) => setInterest(event.target.selectedOptions[0].value)}>
                    {
                        interestOptions.map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))
                    }
                </Form.Select>
            </FloatingLabel>

            <FloatingLabel controlId='floatingComment' label='Comment'>
                <Form.Control
                    className='mb-3'
                    as='textarea'
                    value={comment}
                    onChange={(event) => setComment(event.target.value)} />
            </FloatingLabel>

            <div className="d-grid gap-2">
                <Button type='submit' variant='danger' disabled={buttonDisabled}>{buttonText}</Button>
            </div>
        </Form>

    );
}

export default ContactForm;