import { Col, Container, Row } from "react-bootstrap";

function HeroSection() {
  return (
    <div className="hero-section">
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <p className="display-2">The next evolution in industrial cameras</p>
            <p className="lead">The only <mark><strong>wireless industrial camera</strong></mark> that reduces downtime and increases safety during fault finding in the production process.</p>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeroSection;