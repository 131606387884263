import { Table } from 'react-bootstrap';
import specificationList from '../data/SpecificationList.json';
import Section from '../components/section';

function SpecificationSection() {

  const rowClassName = "py-4";

  return (
    <Section id="specifications" title="Technical Specifications" className="bg-light">
      <Table>
        <tbody>
          {
            specificationList
              .sort((a, b) => (a.name.localeCompare(b.name)))
              .map(({ name, description }, index) => (
                <tr key={index}>
                  <td className={rowClassName}><strong>{name}</strong></td>
                  <td className={rowClassName}>{description}</td>
                </tr>
              ))
          }
        </tbody>
      </Table>
    </Section>
  );
}

export default SpecificationSection;