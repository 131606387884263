import Section from '../components/section';
import { Col, Image, Row } from 'react-bootstrap';
import mobileAppScreenshots from '../images/mobileApp.png';
import appStoreBadge from '../images/appStore.svg';
import playStoreBadge from '../images/playStore.png';

function MobileAppSection() {

    return (
        <Section id="mobileApp" title="Effortless Configuration" className='bg-light'>
            <Row>
                <Col sm={12} md={6}>
                    <p>
                        Our camera is designed for effortless configuration using the Linekam mobile app, making setup a breeze for operators and engineers alike. With an intuitive interface, the app allows users to quickly configure and adjust settings directly from any smartphone.
                        No technical expertise is required! Simply download the app, connect to your Linekam camera via Bluetooth, and you’re ready to go. Whether you’re setting Wi-Fi connections, changing resolution settings, or configuring FTP locations, everything can be done seamlessly in just a few taps.
                        This user-friendly approach ensures that you can focus on what matters most: enhancing safety and efficiency in your operations.
                    </p>
                    <div className='mt-5 text-center'>
                        <a href="https://www.apple.com/app-store/" target="_blank" rel="noreferrer">
                            <Image className='app-store-badge m-2' src={appStoreBadge} />
                        </a>
                        <a href="https://play.google.com/" target="_blank" rel="noreferrer">
                            <Image className='app-store-badge m-2' src={playStoreBadge} />
                        </a>
                    </div>
                </Col>
                <Col className='d-flex justify-content-center align-items-center'>
                    <Image src={mobileAppScreenshots} style={{ maxWidth: '50%' }} />
                </Col>
            </Row>
        </Section>
    );
}

export default MobileAppSection;