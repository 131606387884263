import Section from "../components/section";
import { Button, Card } from 'react-bootstrap';

function BannerSection() {

    return (
        <Section id="banner" title="Banner" hideTitle={true}>
            <Card className='py-3 px-4'>
                <Card.Body>
                    <div className='display-6'>
                        Be Part of the Future: Join Our Exclusive Market Testing Program
                    </div>
                    <Card.Text className='py-3'>
                        We're excited to announce that our camera is in the final stages
                        of prototype testing! As we gear up for market testing, we're looking
                        for enthusiastic early adopters to help shape the future of our innovation.
                        If you'd like to be among the first to experience Linekam and provide
                        valuable feedback, please sign up for our exclusive market testing program
                        using our contact form below. Don't miss this unique opportunity to
                        influence the development of this industrial evolution!
                    </Card.Text>
                    <div className="d-flex justify-content-end">
                        <a href='#contact'><Button className='p-3' variant="danger"><strong>Sign up now!</strong></Button></a>
                    </div>
                </Card.Body>
            </Card>
        </Section>
    );
}

export default BannerSection;