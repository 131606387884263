import './App.css';
import Navigation from './components/navigation';
import HeroSection from './sections/heroSection';
import FeatureSection from './sections/featureSection';
import SpecificationSection from './sections/specificationSection';
import MobileAppSection from './sections/mobileAppSection';
import ContactSection from './sections/contactSection';
import FooterSection from './sections/footerSection';
import UseCaseSection from './sections/useCaseSection';
import BannerSection from './sections/bannerSection';

function App() {
  return (
    <div className='fw-light'>
      <Navigation />
      <HeroSection />
      <BannerSection />
      <FeatureSection />
      <SpecificationSection />
      <UseCaseSection />
      <MobileAppSection />
      <ContactSection />
      <FooterSection />
    </div>
  );
}

export default App;
