import { Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logoLight from '../images/LineKamLogoLight.svg'

function Navigation() {
  return (
    <Navbar expand="lg" className="bg-dark py-3" data-bs-theme="dark">
      <Container>
        <Navbar.Brand className='text-white'>
          <Image className="logo" src={logoLight} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#features" className='text-white nav-link'>Features</Nav.Link>
            <Nav.Link href="#specifications" className='text-white nav-link'>Specifications</Nav.Link>
            <Nav.Link href="#usecases" className='text-white nav-link'>Use Cases</Nav.Link>
            <Nav.Link href="#mobileApp" className='text-white nav-link'>Mobile App</Nav.Link>
            <Nav.Link href="#contact" className='text-white nav-link'>Contact</Nav.Link>
            <Nav.Link href="#faq" className='text-white nav-link'>FAQ</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;