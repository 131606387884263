import { Container } from 'react-bootstrap';

function Section({ id, title, hideTitle = false, children, className }) {

    const mainClassName = `py-5 ${className}`;

    return (
        <div className={mainClassName}>
            <Container>
                <div id={id} className='display-6 mb-5' hidden={hideTitle}>{title}</div>
                {children}
            </Container>
        </div>
    );
}

export default Section;