import { Col, Row } from 'react-bootstrap';
import Section from '../components/section';
import ContactForm from '../components/contactForm';
import FAQ from '../components/faq';

function ContactSection() {

    return (
        <Section id='contact' title='Get in touch...'>
            <Row>
                <Col sm={12} md={6}>
                    <ContactForm />
                </Col>
                <Col>
                    <FAQ />
                </Col>
            </Row>
        </Section>
    );
}

export default ContactSection;